// extracted by mini-css-extract-plugin
export var footer = "OJOY+ynz";
export var title = "X8DVyeZQ";
export var spread = "tt1FER-a";
export var clicked = "quPEdpDO";
export var items = "OfQdzGCD";
export var displayed = "uA1Wau3y";
export var socials = "GKpZo5Jj";
export var annotations = "_6aa+7oEH";
export var copyright = "xuiWhSJJ";
export var logo = "smJPRkQ+";