// extracted by mini-css-extract-plugin
export var hasBorder = "uIJLKdxB";
export var logo = "YWKZA4tC";
export var main = "_38M8NLoE";
export var langSwitch = "hlbW6e3n";
export var langItem = "nZMBvx00";
export var disabled = "IOKfFJdC";
export var navbarBurger = "qoWOq+rT";
export var progress = "KEerd6EJ";
export var show = "xHRCY9I2";
export var tryTidbCloudBtn = "NDDuEZ5K";
export var NavContainer = "uGmwVDJU";
export var hidden = "mmdVaJ9w";